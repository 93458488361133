import { makeStyles } from '@material-ui/core/styles'
import { hexToRGBA } from '../../libs/helpers'

enum Flex {
  FLEX_START = 'flex-start',
  CENTER = 'center',
  FLEX_END = 'flex-end',
  SPACE_BETWEEN = 'space-between',
  SPACE_AROUND = 'space-around',
  SPACE_EVENLY = 'space-evenly',
}

export const onboardingStyles = makeStyles((theme) => ({
  pageWrapper: {
    'justifyContent': 'center',
    'marginTop': 12,
    'width': '100%',
    'display': 'flex',
    'alignItems': 'center',
    '@media (max-width: 767px)': {
      backgroundColor: '#fff',
    },
  },
  wrapper: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,
    marginTop: 12,
    maxWidth: 500,
    gap: 16,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '95vw',
      marginBottom: 108,
    },
  },
  stepContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '0 40px 30px',
    boxSizing: 'border-box',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '0 24px 30px',
    },
  },
  helpCenter: {
    paddingBottom: 80,
    marginTop: 12,
    maxWidth: 500,
    gap: 16,
    width: '100%',
    minHeight: 'fit-content',
    overflow: 'auto',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '95vw',
      paddingBottom: 40,
    },
  },
  title: {
    fontWeight: 500,
    fontSize: 32,
    color: '#505358',
    alignSelf: Flex.FLEX_START,
  },
  errorTitle: {
    marginTop: 32,
    fontSize: 30,
    fontWeight: 500,
    color: '#505358',
    alignSelf: Flex.CENTER,
  },
  faqHeaderBar: {
    display: 'flex',
  },
  faqHeader: {
    fontSize: 32,
    fontWeight: 500,
    color: '#505358',
    alignSelf: Flex.FLEX_START,
    marginBottom: 24,
    [theme.breakpoints.down(600)]: {
      fontSize: 30,
      lineHeight: '39px',
      marginBottom: 16,
    },
  },
  verifyHeader: {
    fontSize: 32,
    fontWeight: 500,
    color: '#505358',
    alignSelf: Flex.FLEX_START,
    marginBottom: 24,
    lineHeight: '130%',
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  cardTitle: {
    fontWeight: 600,
    fontSize: 16,
    color: '#505358',
    alignSelf: Flex.FLEX_START,
  },
  description: {
    fontWeight: 500,
    fontSize: 16,
    color: '#505358',
    alignSelf: Flex.FLEX_START,
  },
  twoFieldsAlways: {
    display: 'flex',
    gap: 16,
    justifyContent: Flex.SPACE_BETWEEN,
    width: '100%',
  },
  twoFieldsOnlyDesktop: {
    display: 'flex',
    gap: 16,
    justifyContent: Flex.SPACE_BETWEEN,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  body: {
    fontSize: 16,
    fontWeight: 500,
    color: '#757575',
    alignSelf: Flex.FLEX_START,
    [theme.breakpoints.down('xs')]: {
      color: '#505358',
    },
  },
  errorBody: {
    fontSize: 16,
    fontWeight: 500,
    color: '#898989',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      color: '#505358',
    },
  },
  account: {
    fontSize: 16,
    color: '#7E7E7E',
  },
  pageLink: {
    fontFamily: theme.typography.fontFamily,
    marginLeft: 4,
    cursor: 'pointer',
    alignSelf: 'center',
    marginTop: 1,
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  cancelLinkWrapper: {
    width: '100%',
    marginTop: 24,
    display: 'flex',
    justifyContent: 'center',
  },
  cancelLink: {
    cursor: 'pointer',
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  actionText: {
    display: 'flex',
    justifyContent: Flex.CENTER,
    justifySelf: Flex.CENTER,
    marginTop: 24,
  },
  buttonWrapper: {
    width: '100%',
    marginTop: 'auto',
    marginBottom: 0,
  },
  inviteMobileButtonWrapper: {
    width: '100%',
    marginTop: 'auto',
    marginBottom: 0,
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      position: 'fixed',
      zIndex: 1001,
      bottom: 0,
      backgroundColor: '#FFF',
      padding: '0px 10px 40px',
    },
  },
  actionBtn: {
    width: '100%',
    justifySelf: Flex.CENTER,
    maxHeight: 48,
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: 20,
    marginBottom: 0,
  },
  helpWrapper: {
    width: 440,
    margin: '0px auto',
    display: 'flex',
    justifyContent: Flex.FLEX_END,
  },
  dialogContainer: {
    height: '85vh',
    margin: '32px 0px',
  },
  faqTitle: {
    fontSize: '16px',
    fontWeight: 600,
    display: 'flex',
    color: '#757575',
    flexDirection: 'column',
    justifyContent: Flex.CENTER,
  },
  link: {
    'width': '100%',
    'display': 'grid',
    'grid-template-columns': '1fr min-content',
    'padding': '0.9rem 0',
    'borderBottom': '1px solid #efefef',
    'textDecoration': 'none',
    'outline': 'none',
    'color': '#7b7c7d',
    '@media (min-width: 600px)': {
      padding: '0.9rem 100px 0.9rem 100px !important',
    },
  },
  detailContainer: {
    maxHeight: 'calc(92vh)',
    overflow: 'auto',
    width: '80%',
    margin: '0px auto',
  },
  answer: {
    [theme.breakpoints.up(767)]: {
      margin: '0 auto',
    },
  },
  detailContent: {
    color: '#545454',
    fontSize: '18px',
    lineHeight: '27px',
  },
  noContent: {
    width: '100%',
    margin: 'auto',
    fontSize: 24,
  },
  loading: {
    marginTop: 150,
  },
  faqList: {
    width: '100%',
    [theme.breakpoints.down(500)]: {
      maxWidth: theme.spacing(89),
      overflow: 'auto',
    },
  },
  faqItem: {
    'marginBottom': theme.spacing(1.5),
    'color': '#526A8C',
    'borderRadius': 8,
    'backgroundColor': '#F3F3F3',
    'boxShadow': 'none',
    [theme.breakpoints.up(500)]: {
      margin: '10px auto',
      width: '100%',
    },

    '&:first-child': {
      borderRadius: 8,
    },
    '&:before': {
      backgroundColor: 'transparent',
    },
    '& .MuiTypography-h6': {
      fontSize: 18,
      [theme.breakpoints.down(767)]: {
        fontSize: 15,
      },
    },
    '& p': {
      fontSize: 16,
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
      fontSize: '2rem',
    },
    '& .MuiAccordionSummary-root': {
      [theme.breakpoints.down(767)]: {
        backgroundColor: '#F8F9FA',
        borderRadius: 8,
      },
    },
    '& .MuiAccordionDetails-root': {
      [theme.breakpoints.down(767)]: {
        backgroundColor: '#F8F9FA',
        borderRadius: '0 0 8px 8px',
      },
    },
  },
  infoText: {
    margin: '24px 0',
    fontWeight: 600,
    fontSize: 30,
    textAlign: 'left',
  },
  closeIcon: {
    transform: 'rotate(45deg)',
    marginLeft: '24px',
    color: '#505358',
    position: 'absolute',
    left: 24,
  },
  closeIconWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: Flex.CENTER,
    alignItems: Flex.CENTER,
    padding: '16px 0px',
    borderBottom: '1px solid #505358',
  },
  faqListTitle: {
    fontSize: '24px',
    fontWeight: 600,
  },
  checkbox: {
    color: 'red',
    padding: 0,
  },
  termsCheckboxContainer: {
    'marginTop': '24px',
    'display': 'flex',
    'alignItems': Flex.FLEX_START,
    'justifyContent': Flex.FLEX_START,
    'alignSelf': Flex.FLEX_START,
    '& span': {
      paddingTop: '0',
    },
  },
  terms: {
    marginLeft: '-8px',
    color: '#7E7E7E',
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      marginLeft: '-16px',
    },
  },
  contactLink: {
    margin: '8px auto',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight: 500,
    fontSize: 18,
    textDecoration: 'underline',
    [theme.breakpoints.down(600)]: {
      fontSize: 16,
    },
  },
  supportNameEmphasis: {
    fontWeight: 700,
  },
  termsLink: {
    cursor: 'pointer',
    fontWeight: 600,
  },
  dataRatesText: {
    fontSize: 12,
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
  iconBtn: {
    'width': 12,
    'height': 12,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiSvgIcon-root': {
      color: 'rgb(0, 60, 114)',
    },
  },
  cardBase: {
    width: '100%',
    borderRadius: 8,
    display: 'flex',
  },
  innerWrapper: {
    width: '100%',
    padding: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: Flex.SPACE_BETWEEN,
  },
  card: {
    border: 'solid 1px',
    borderColor: '#E0E0E0',
    padding: '1px',
  },
  cardSelected: {
    border: 'solid 2px',
    borderColor: theme.palette.primary.main,
  },
  cardLeft: {
    display: 'flex',
    flexDirection: 'column',
    gap: 6,
  },
  cardBody: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: Flex.CENTER,
    cursor: 'pointer',
    justifyContent: Flex.SPACE_BETWEEN,
  },
  addressContainer: {
    marginLeft: 8,
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: Flex.CENTER,
    cursor: 'pointer',
    justifyContent: Flex.SPACE_BETWEEN,
  },
  cardAddress: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '22px',
    textAlign: 'left',
    color: '#757575',
  },
  backIconWrapper: {
    position: 'absolute',
    top: '43px',
    left: '43px',
    display: 'flex',
    gap: 6,
    alignItems: Flex.CENTER,
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      top: '41px',
      left: '10px',
    },
  },
  accordion: {
    '&.MuiPaper-outlined': {
      borderTop: 'none',
      borderBottom: '1px solid #C4C4C4',
      borderLeft: 'none',
      borderRight: 'none',
    },
    '&.MuiAccordion-rounded:last-child': {
      borderRadius: '0px',
    },
  },
  details: {
    userSelect: 'none',
    wordWrap: 'break-word',
    [theme.breakpoints.down(320)]: {
      '& p': {
        maxWidth: '85vw',
      },
    },
  },
  warningWrapper: {
    'padding': 25,
    'borderRadius': '50%',
    'backgroundColor': hexToRGBA(theme.palette.primary.main, 0.1),
    [theme.breakpoints.down(600)]: {
      marginTop: 50,
    },
    '&.fullsceen': {
      marginTop: 140,
    },
  },
  warningIcon: {
    height: '48px',
    fill: theme.palette.primary.main,
  },
  helpCenterIcon: {
    position: 'absolute',
    top: 64,
    right: 24,
  },
  backWrapper: {
    width: '524px',
    height: 32,
    display: 'flex',
    marginTop: '-32px',
    justifyContent: Flex.FLEX_START,
  },
  backButton: {
    height: 42,
    width: 42,
    cursor: 'pointer',
    [theme.breakpoints.down(300)]: {
      height: 24,
      width: 24,
    },
  },
  subText: {
    fontWeight: 400,
    width: '100%',
    color: '#757575',
    fontSize: 20,
    alignSelf: Flex.CENTER,
    textAlign: 'left',
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('xs')]: {
      fontWeight: 500,
      fontSize: 16,
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    minHeight: 200,
    [theme.breakpoints.down('xs')]: {
      minHeight: 'unset',
      margin: '16px auto',
    },
  },
  welcomeWrapper: {
    maxWidth: 550,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: Flex.SPACE_BETWEEN,
    width: '90%',
    margin: '0px auto',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      margin: '0px 24px',
    },
  },
  welcomeContentWrapper: {
    'maxWidth': 550,
    'display': 'flex',
    'flexDirection': 'column',
    'justifyContent': Flex.SPACE_BETWEEN,
    'width': '100%',
    'margin': '0 auto',
    'textAlign': 'center',
    '@media (max-width: 300px)': {
      paddingBottom: '32px',
    },
  },
  welcomeContent: {
    'marginTop': 40,
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'center',
    '@media (max-width: 300px)': {
      marginTop: 0,
    },
  },
  optionsWrapper: {
    width: '100%',
  },
  optionWrapper: {
    'padding': '0px 20px',
    'borderRadius': 10,
    'display': 'flex',
    'justifyContent': Flex.SPACE_BETWEEN,
    'margin': '0 auto',
    'maxWidth': 400,
    'color': '#7B7C7D',
    '&:first-child': {
      cursor: 'pointer',
    },
  },
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
    margin: '32px 0px',
    [theme.breakpoints.down(600)]: {
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down(300)]: {
      flexDirection: 'column',
    },
  },
  contactCompany: {
    fontWeight: 700,
    width: '100%',
    color: '#505358',
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: 0.5,
    alignSelf: 'center',
    textAlign: 'center',
    marginTop: '16px',
  },
  contactAddress: {
    fontWeight: 400,
    width: '100%',
    color: '#505358',
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: 0.5,
    alignSelf: 'center',
    textAlign: 'center',
  },
  toolBar: {
    width: '100%',
    display: 'flex',
    backgroundColor: '#FFF',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: 'none',
    position: 'fixed',
    bottom: 0,
    [theme.breakpoints.down(600)]: {
      padding: '16px 0',
    },
  },
  dialogButtonWrapper: {
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  regErrorTitle: {
    fontSize: 30,
    fontWeight: 500,
    color: '#505358',
  },
  regErrorBody: {
    fontSize: 16,
    fontWeight: 500,
    color: '#505358',
  },
  expandIcon: {
    transform: 'rotate(-90deg)',
    color: '#898989',
    zIndex: 1,
  },
  termsTitle: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  helpContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,
    maxWidth: 500,
    gap: 16,
    width: '100%',
    position: 'relative',
    margin: '0px auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100vw',
      marginBottom: 108,
    },
  },
  listWrapper: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 10px 10px 0px',
    borderBottom: '1px solid #C4C4C4',
  },
  detailBackButton: {
    position: 'absolute',
    marginTop: '-5px',
    marginLeft: '-10px',
    height: 24,
    width: 24,
    fontSize: 24,
    color: '#505358',
    [theme.breakpoints.down(600)]: {
      '&.list': {
        color: '#FFF',
      },
      'marginLeft': '9px',
    },
    [theme.breakpoints.down(300)]: {
      height: 24,
      width: 24,
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
    paddingBottom: '32px',
  },
  detailHeader: {
    width: '100%',
    maxWidth: '500px',
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
    paddingBottom: '9px',
    borderBottom: '1px solid #C4C4C4',
    position: 'fixed',
    top: 0,
    zIndex: 101,
    height: 55,
    [theme.breakpoints.down(600)]: {
      'alignItems': 'flex-end',
      'width': '100vw',
      '&.list': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  newHelpListTitle: {
    color: '#505358',
    fontWeight: 600,
    // marginLeft: '8px',
    fontSize: 16,
  },
  faqDetailWrapper: {
    width: '90%',
    margin: '96px auto 0px',
  },
  detailTitle: {
    fontWeight: 500,
    margin: '0px auto',
    color: '#505358',
    fontSize: '20px',
    [theme.breakpoints.down(600)]: {
      '&.list': {
        color: '#FFF',
      },
      'fontSize': '16px',
    },
    [theme.breakpoints.down(300)]: {
      fontSize: '14px',
    },
  },
  faqDetailTitle: {
    fontWeight: 500,
    fontSize: 32,
    color: '#505358',
  },
  faqDetailDescription: {
    fontWeight: 500,
    fontSize: 16,
    color: '#757575',
  },
  longDetailTitle: {
    fontWeight: 500,
    margin: '0px auto',
    color: theme.palette.primary.main,
    [theme.breakpoints.down(600)]: {
      paddingTop: '4px',
      fontSize: '14px',
    },
  },
  newHelpCenter: {
    paddingBottom: 80,
    // marginTop: 12,
    maxWidth: 500,
    gap: 16,
    width: '100%',
    minHeight: 'fit-content',
    overflow: 'auto',
    marginTop: 64,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '95vw',
      paddingBottom: 40,
      marginLeft: '5vw',
    },
  },
}))
