import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  allowedGenders,
  ethnicities,
  races,
  StepType,
} from '../../../../constants'
import { selectLanguageContent } from '../../../../features/translation'
import { translateOptions } from '../../../../libs/helpers'
import { Button, DateTextField, FilledSelect } from '../../../../ui'
import { onboardingStyles } from '../../../_styles/registrationStyles'
import { selectNetworkSettings } from '../../../network/model'
import { NetworkSettings } from '../../../network/type'
import { selectProfileIsLoading } from '../../../profile/model'
import { getName } from '../../helpers'
import { saveAccountInfo } from '../../model/registerSlice'
import { RegisterStepProps } from '../../model/type'

export const AccountInformation = (props: RegisterStepProps) => {
  const { handleNext, formik, isInvite } = props
  const classes = onboardingStyles()
  const type = StepType.ACC_INFO
  const i18n = useSelector(selectLanguageContent)
  const currentNetwork = useSelector(selectNetworkSettings)
  const [dateFormatCorrect, setDateFormatCorrect] = useState(true)
  const [dateValid, setDateValid] = useState(true)
  const loadingProfile = useSelector(selectProfileIsLoading)

  const dispatch = useDispatch()

  const values = formik.values[type]
  const errors = formik.errors[type]
  const touched = formik.touched[type]

  const goNext = () => {
    dispatch(saveAccountInfo(values))
    if (
      //Submit form now if no need to collect address info
      currentNetwork &&
      !currentNetwork.collectStreetAddress &&
      !currentNetwork.collectCity &&
      !currentNetwork.collectState &&
      !currentNetwork.collectZip
    ) {
      formik.submitForm()
    } else {
      handleNext()
    }
  }

  useEffect(() => {
    sessionStorage.removeItem('codeSent')
  }, [])

  const {
    collectDateOfBirth,
    collectDateOfBirthRequired,
    collectSex,
    collectSexRequired,
    collectRace,
    raceRequired,
    collectEthicity,
    ehtnicityRequired,
  } = (currentNetwork as NetworkSettings) || {}

  const updateDate = (value: string) => {
    setDateFormatCorrect(
      Boolean(value.match(/(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)\d{2}/))
    )
    if (moment(value, 'MM/DD/YYYY', true).isValid()) {
      setDateValid(true)
      formik.setFieldValue(
        getName('dob', type),
        moment(value).format('MM/DD/YYYY') || ''
      )
    } else {
      setDateValid(false)
      formik.setFieldValue(getName('dob', type), '')
    }
  }

  const renderDOB = () =>
    collectDateOfBirth && (
      <DateTextField
        label={i18n.date_of_birth}
        value={
          values.dob
            ? moment(values.dob.toString().split('Z')[0]).format('MM/DD/YYYY')
            : null
        }
        i18n={i18n}
        onBlur={formik.handleBlur}
        error={Boolean(touched?.dob) && Boolean(errors?.dob)}
        helperText={(touched?.dob && errors?.dob) || ''}
        name={getName('dob', type)}
        handleChange={updateDate}
        disabled={values.hasPrefillDOB}
        inputProps={{
          inputMode: 'numeric',
        }}
        required={collectDateOfBirthRequired}
      />
    )

  const renderSexAtBirth = () =>
    collectSex && (
      <FilledSelect
        label={i18n.sex}
        options={translateOptions(allowedGenders, i18n)}
        value={values?.sexAtBirth}
        fullWidth
        name={getName('sexAtBirth', type)}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={touched?.sexAtBirth && Boolean(errors?.sexAtBirth)}
        helperText={touched?.sexAtBirth ? errors?.sexAtBirth : ''}
        required={collectSexRequired}
      />
    )

  const renderRace = () =>
    collectRace && (
      <FilledSelect
        label={i18n.race}
        options={translateOptions(races, i18n)}
        value={values.race}
        fullWidth
        name={getName('race', type)}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={touched?.race && Boolean(errors?.race)}
        helperText={touched?.race ? errors?.race : ''}
        required={raceRequired}
      />
    )

  const renderEthicity = () =>
    collectEthicity && (
      <FilledSelect
        label={i18n.ethnicity}
        options={translateOptions(ethnicities, i18n)}
        value={values.ethnicity}
        fullWidth
        name={getName('ethnicity', type)}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={touched?.ethnicity && Boolean(errors?.ethnicity)}
        helperText={touched?.ethnicity ? errors?.ethnicity : ''}
        required={ehtnicityRequired}
      />
    )

  return (
    <form className={classes.content}>
      <Typography className={classes.title} color="primary" variant="h1">
        {i18n.enter_account_info_header}
      </Typography>
      <Typography
        className={classes.body}
        variant="body1"
        align="left"
        style={{ marginBottom: 24 }}
      >
        {i18n.enter_account_info_instruction}
      </Typography>
      {renderDOB()}
      {renderSexAtBirth()}
      {renderRace()}
      {renderEthicity()}
      <div
        className={
          isInvite ? classes.inviteMobileButtonWrapper : classes.buttonWrapper
        }
      >
        <Button
          className={classes.actionBtn}
          onClick={goNext}
          disabled={
            !formik.isValid ||
            loadingProfile ||
            (collectDateOfBirth && !dateFormatCorrect) ||
            !dateValid
          }
          isLoading={formik.isSubmitting}
        >
          {i18n.continue_button}
        </Button>
      </div>
    </form>
  )
}
