import { css, Global } from '@emotion/react'
import {
  createTheme,
  MuiThemeProvider,
  ThemeOptions,
} from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { AppDialog } from './features/dialog/application-dialog'
import { InactivityTimer } from './features/inactivity-timer'
import { Notification } from './features/notifications'
import { setNotification } from './features/pusher-notifications/model'
import { getLanguageFile } from './features/translation/model'
import { getUserPermission } from './features/user/model/userTenantSlice'
import { isLoggedIn } from './libs/helpers'
import { lsClient } from './ls-client'
import { Pages } from './pages'
import {
  getNetworkSettings,
  selectNetworkSettings,
} from './pages/network/model'
import { NetworkSettings } from './pages/network/type'
import { getProfileData, selectProfileData } from './pages/profile/model'
import { getDefaultSignupTenant } from './pages/register/model/registerSlice'
import { selectActiveTenant } from './pages/tenant-selection/tenantSlice'
import { Loading } from './ui'
import { defaultTheme } from './ui/theme'

const FontFaceObserver = require('fontfaceobserver')

const channel = new BroadcastChannel('pusher-sw-messages')

export const Application = () => {
  const networkSettings = useSelector(selectNetworkSettings)
  const loggedIn = isLoggedIn()
  const activeTenant =
    useSelector(selectActiveTenant) ||
    lsClient.getUserLSByKey('tenantId') ||
    localStorage.getItem('tenantId') ||
    sessionStorage.getItem('tenantId')
  const dispatch = useDispatch()
  const profileData = useSelector(selectProfileData)
  const [fontsLoaded, setFontsLoaded] = useState(false)
  const [theme, setTheme] = useState<ThemeOptions>(defaultTheme)
  const [favicon, setFavicon] = useState(
    sessionStorage.getItem('favicon') || ''
  )
  const [appName, setAppName] = useState(
    sessionStorage.getItem('appName') || ''
  )
  const isInvite = window.location.pathname.includes('email-verification')
  const font = new FontFaceObserver('Avenir Regular')
  useEffect(() => {
    setTimeout(() => {
      setFontsLoaded(true)
    }, 3000)
    font.load().then(() => {
      setFontsLoaded(true)
    })
  }, [])

  useEffect(() => {
    // Pusher - Push notification listener
    channel.addEventListener('message', (event) => {
      dispatch(setNotification(event.data))
      console.log('Received', event.data)
    })

    return () => {
      channel.removeEventListener('message', () => {
        console.log('REMOVED')
      })
    }
  }, [])

  useEffect(() => {
    if (activeTenant) {
      if (!networkSettings || activeTenant !== networkSettings.tenantID) {
        localStorage.setItem('tenantId', activeTenant)
        dispatch(getNetworkSettings(activeTenant))
      }
      if (loggedIn) {
        if (!profileData.email) {
          dispatch(getProfileData())
        }
        const userSelectedTenant = lsClient.getUserLSByKey('tenantId')
        if (userSelectedTenant) {
          dispatch(getUserPermission())
        }
      }
      dispatch(getLanguageFile('en', activeTenant))
    } else if (!networkSettings && !activeTenant && !isInvite) {
      dispatch(getDefaultSignupTenant())
    }
  }, [activeTenant, loggedIn])

  const updateTheme = (networkSettings: NetworkSettings) => {
    setTheme((prev) => ({
      ...prev,
      palette: {
        ...prev.palette,
        primary: {
          ...prev.palette?.primary,
          main: networkSettings.primaryColor || '#003c71',
        },
        secondary: {
          ...prev.palette?.secondary,
          main: networkSettings.secondaryColor || '#87878d',
        },
        error: {
          ...prev.palette?.error,
          main: networkSettings.errorColor || '#ff6464',
        },
        warning: {
          ...prev.palette?.warning,
          main: networkSettings.warningColor || '#ffc12a',
        },
        success: {
          ...prev.palette?.success,
          main: networkSettings.successColor || '#009104',
        },
      },
      overrides: {
        ...prev.overrides,
        MuiButton: {
          ...prev.overrides?.MuiButton,
          root: {
            ...prev.overrides?.MuiButton?.root,
            borderRadius: networkSettings.buttonStyle === 0 ? 8 : 50,
          },
        },
        MuiTabs: {
          ...prev.overrides?.MuiTabs,
          indicator: {
            ...prev.overrides?.MuiTabs?.indicator,
            backgroundColor: networkSettings.primaryColor,
          },
        },
      },
    }))
  }

  useEffect(() => {
    if (networkSettings) {
      if (networkSettings.favicon) {
        sessionStorage.setItem('favicon', networkSettings.favicon)
        setFavicon(networkSettings.favicon)
      } else sessionStorage.removeItem('favicon')
      if (networkSettings.appName) {
        sessionStorage.setItem('appName', networkSettings.appName)
        setAppName(networkSettings.appName)
      } else sessionStorage.removeItem('appName')
      if (networkSettings.appleAppDownloadUrl) {
        sessionStorage.setItem(
          'appleStoreId',
          networkSettings.appleAppDownloadUrl.split('/id')[1]
        )
      }
      updateTheme(networkSettings)
    }
  }, [networkSettings])

  return (
    <MuiThemeProvider theme={createTheme(theme)}>
      <HelmetProvider>
        <Helmet>
          <title>{appName}</title>
          <link id="favicon" rel="icon" href={favicon} type="image/x-icon" />
        </Helmet>
        <AppDialog />
        <Notification />
        <InactivityTimer>
          {fontsLoaded ? <Pages /> : <Loading />}
        </InactivityTimer>
        <Global styles={styles} />
      </HelmetProvider>
    </MuiThemeProvider>
  )
}
const styles = css`
  #root {
    min-height: 100vh;
    display: flex;
  }
  .carousel .slide {
    background-color: transparent; !important;
  }
`
